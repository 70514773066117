<template>
    <Page
        :title="$t('messages.list.title')"
        :watch-for-scroll="true"
        :content-loading="loading"
        content-class=""
        @reachedBottom="loadMore()"
    >
        <TopicItem
            v-for="item of items"
            :key="item.value.id"
            :topic="item.value"
            @action="handleAction"
        />
        <div
            v-if="!loading && items.length === 0"
            class="flex flex-col items-center justify-center w-full h-full"
        >
            <div class="text-sm text-gray-400">{{ $t('messages.list.is_empty') }}</div>
        </div>
    </Page>
</template>

<script>
import Page from '@/components/ui/Page';
import AuthMixin from '@/mixins/AuthMixin';
import InfiniteScrollMixin from '@/mixins/InfiniteScrollMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import TopicItem from '@/components/messages/TopicItem';
import EventBus from '@/utils/EventBus';
import MessagingCounterMixin from '@/mixins/MessagingCounterMixin';

export default {
    components: { TopicItem, Page },

    mixins: [AuthMixin, InfiniteScrollMixin, NotifyMixin, MessagingCounterMixin],

    data() {
        return {
            ITEMS_LIMIT: 20,
            CREATE_ITEM_NOTIFICATION_ID: 'MSGS/create_topic',
            UPDATE_ITEM_NOTIFICATION_ID: 'MSGS/update_topic',
        };
    },

    watch: {
        currentProfile() {
            this.loadInitial();
        },
    },

    mounted() {
        EventBus.on('mark-messaging-topic-as-read', this.markTopicAsRead);

        // re-init counter
        // TODO: remove it when WST notifications about counter is implemented
        this.initialize();
    },

    beforeUnmount() {
        EventBus.off('mark-messaging-topic-as-read', this.markTopicAsRead);
    },

    methods: {
        loadItems(options = {}) {
            return this.$msgsDataProvider.getList('topics', options);
        },

        handleAction(topic, key) {
            switch (key) {
            case 'MARK_READ':
                this.$msgsDataProvider
                    .update('topics', { id: topic.id, lastReadTime: new Date().toISOString() })
                    .then(() => {
                        topic.unread = false;
                        EventBus.emit('messaging-topic-read-status-update');
                    })
                    .catch(err => this.notifyError(err.message));
                break;
            case 'MARK_UNREAD':
                this.$msgsDataProvider
                    .unread('topics', { id: topic.id })
                    .then(() => {
                        topic.unread = true;
                        EventBus.emit('messaging-topic-read-status-update');
                    })
                    .catch(err => this.notifyError(err.message));
                break;
            }
        },

        markTopicAsRead(id) {
            const item = this.items.find(({ value }) => value.id === id);
            if (item) {
                item.value.unread = false;
                this.items = [...this.items];
                EventBus.emit('messaging-topic-read-status-update');
            }
        },
    },
};
</script>
