<template>
    <div
        ref="root"
        class="swipeable"
    >
        <div class="swipeable-actions">
            <div
                v-for="action in actions"
                :key="action.key"
                class="swipeable-action"
                @click="handleClickAction(action.key)"
            >
                {{ action.value }}
            </div>
        </div>
        <div
            ref="container"
            class="swipeable-container"
            @scroll="handleSwipe"
        >
            <div
                class="swipeable-card"
                :class="{ active }"
            >
                <div
                    v-if="active"
                    class="absolute w-full h-full"
                    @click="close"
                ></div>
                <slot name="default"></slot>
            </div>
            <div
                class="swipeable-endmark"
                :style="{ right: actions.length * -25 + '%' }"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        actions: {
            type: Array,
            required: true,
        },
    },

    emits: ['action'],

    data() {
        return {
            active: false,
        };
    },

    mounted() {
        window.addEventListener('mousedown', this.handleTouchOutside);
        window.addEventListener('touchstart', this.handleTouchOutside);
    },

    beforeUnmount() {
        window.removeEventListener('mousedown', this.handleTouchOutside);
        window.removeEventListener('touchstart', this.handleTouchOutside);
    },

    methods: {
        open() {
            this.$refs.container.scrollTo({ right: 0, behavior: 'smooth' });
        },

        close() {
            this.$refs.container.scrollTo({ left: 0, behavior: 'smooth' });
        },

        handleTouchOutside(event) {
            if (!(this.$refs.root === event.target || this.$refs.root.contains(event.target))) {
                this.close();
            }
        },

        handleSwipe(event) {
            event.preventDefault();

            this.active = event.target.scrollLeft > 0;
        },

        handleClickAction(key) {
            this.$emit('action', key);
            this.close();
        },
    },
};
</script>

<style scoped>
.swipeable {
    @apply relative;
}

.swipeable-actions {
    @apply absolute w-full h-full z-10;
    @apply flex justify-end;
    @apply bg-purple-600;
}

.swipeable-action {
    @apply flex justify-center items-center text-center w-1/4 cursor-pointer;
    @apply font-ffdin font-bold text-white uppercase text-sm leading-4 p-2 pt-3;
}

.swipeable-action:nth-child(2) {
    @apply bg-purple-500;
}

.swipeable-action:active {
    @apply bg-purple-700;
}

.swipeable-container {
    @apply relative w-full overflow-x-scroll overflow-y-hidden;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.swipeable-container::-webkit-scrollbar {
    display: none;
}

.swipeable-card {
    @apply relative w-full h-full bg-white z-20;
    scroll-snap-align: start;
}

.swipeable-card.active {
    @apply rounded-r-2xl;
}

.swipeable-endmark {
    @apply absolute w-px h-px;
    scroll-snap-align: end;
}
</style>
