<template>
    <Swipeable
        class="topic"
        :actions="actions"
        @action="handleAction"
    >
        <router-link
            class="px-6 lg:px-4 h-16 flex justify-between items-center w-screen lg:w-full relative"
            :class="{ unread: topic.unread }"
            :to="{ name: 'messages.details', params: { topicId: topic.id } }"
        >
            <div class="unread-indicator"></div>
            <div class="flex flex-col justify-between w-full h-10 overflow-hidden">
                <div class="flex justify-between items-center">
                    <div class="topic-title mr-1">{{ topic.subject }}</div>
                    <icon
                        v-if="topic.attachmentsNumber > 0"
                        name="attach"
                        class="w-3 ml-1 transform rotate-45 text-gray-400"
                    />
                </div>
                <div class="topic-descr">{{ topic.messagePreview }}</div>
            </div>
            <div class="flex flex-col justify-between items-end h-10 ml-2">
                <div class="text-gray-400 text-xs whitespace-nowrap leading-5">{{ date }}</div>
                <!--                <div class="messages-counter" v-if="unreadMessagesCount">-->
                <!--                    <icon name="message" class="messages-counter-icon block" />-->
                <!--                    <span class="messages-counter-number">{{ unreadMessagesCount }}</span>-->
                <!--                </div>-->
            </div>
        </router-link>
    </Swipeable>
</template>

<script>
import { useI18n } from 'vue-i18n';
import Swipeable from '@/components/ui/Swipeable';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import Icon from '@/components/ui/Icon';

export default {
    components: { Swipeable, Icon },

    mixins: [CommunityTimezoneMixin],

    props: {
        topic: {
            type: Object,
            required: true,
        },
    },

    emits: ['action'],

    setup() {
        const { t } = useI18n();
        return { t };
    },

    computed: {
        date() {
            const m = this.parseDateTime(this.topic.createdAt);
            const now = new Date();

            let format = 'D MMM YYYY';

            if (m.isSame(now, 'day')) {
                format = 'hh:mm a';
            } else if (m.isSame(now, 'year')) {
                format = 'D MMM';
            }

            return this.formatDateTime(m, format);
        },

        actions() {
            return [
                // { key: 'ARCHIVE', value: this.t('messages.actions.archive') },
                this.topic.unread
                    ? { key: 'MARK_READ', value: this.t('messages.actions.mark_as_read') }
                    : { key: 'MARK_UNREAD', value: this.t('messages.actions.mark_as_unread') },
            ];
        },
    },

    methods: {
        handleAction(key) {
            this.$emit('action', this.topic, key);
        },
    },
};
</script>

<style scoped>
.topic:after {
    @apply absolute left-4 right-4 z-30 border-b;
    content: '';
}

.topic-title,
.topic-descr {
    @apply whitespace-nowrap overflow-hidden overflow-ellipsis;
}

.topic-title {
    @apply text-md2;
}

.topic-descr {
    @apply text-2xs;
}

.unread-indicator {
    @apply absolute left-2.5 top-5 w-1.5 h-1.5 rounded-full bg-purple-600 hidden;
}

.messages-counter {
    @apply relative text-purple-600 flex items-center justify-center;
    width: 18px;
    height: 18px;
}

.messages-counter-icon {
    @apply absolute block w-full h-full;
}

.messages-counter-number {
    @apply text-2xs leading-none font-ffdin font-bold;
}

.unread .unread-indicator {
    @apply block;
}

.unread .topic-title,
.unread .topic-descr {
    @apply text-purple-600;
}
</style>
